import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
    const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";
    const userRole = localStorage.getItem("userRole");
    const location = useLocation();

    // Block cdjti user from accessing entries page
    if (isAuthenticated && 
        userRole === "viewer" && 
        localStorage.getItem("rememberedUsername") === "cdjti" && 
        location.pathname === "/entries") {
        return <Navigate to="/games" />;
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }

    return children;
};

export default ProtectedRoute;