import { Box } from "@mui/material";
import Header from "../../components/Header";

const GamesTokens = () => {
    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Games & Tokens" subtitle="Interactive Games"/>
            </Box>
            <Box mt="20px" display="flex" flexDirection="column" gap="30px">
                <iframe 
                    src="https://live.dot.vu/p/adsperianx/olympics-tapping-game/"
                    style={{
                        width: '100%',
                        height: '800px',
                        border: 'none',
                        borderRadius: '8px',
                        overflow: 'hidden'
                    }}
                    title="Olympics Tapping Game"
                />
                <iframe
                    src="https://live.dot.vu/p/adsperianx/whack-a-mole/"
                    style={{
                        width: '100%',
                        height: '800px',
                        border: 'none',
                        borderRadius: '8px',
                        overflow: 'hidden'
                    }}
                    title="Whack-a-Mole Game"
                />
                <iframe
                    src="https://live.dot.vu/p/adsperianx/the-magical-unicorn-game/"
                    style={{
                        width: '100%',
                        height: '800px',
                        border: 'none',
                        borderRadius: '8px',
                        overflow: 'hidden'
                    }}
                    title="The Magical Unicorn Game"
                />
            </Box>
        </Box>
    );
};

export default GamesTokens;