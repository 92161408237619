import { Box } from "@mui/material";
import Header from "../../components/Header";

const WhatsappAI = () => {
    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Whatsapp AI Bot" subtitle="Chat & Voice AI/Bot - Whatsapp"/>
            </Box>
        </Box>
    );
};

export default WhatsappAI;