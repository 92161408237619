import { Box } from "@mui/material";
import Header from "../../components/Header";

const SurveyEntries = () => {
    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Survey Entries" subtitle="Survey Forms & Reports"/>
            </Box>
        </Box>
    );
};

export default SurveyEntries;