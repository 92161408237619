import { Box } from "@mui/material";
import Header from "../../components/Header";

const EventRegistration = () => {
    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Registration Entries" subtitle="VIP & Guests Registrations Record/List"/>
            </Box>
        </Box>
    );
};

export default EventRegistration;