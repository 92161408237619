import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Navigate, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./scenes/login";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import ContestEntries from "./scenes/entries";
import GamesTokens from "./scenes/games";
import WhatsappAI from "./scenes/aiwhatsapp";
import EventAttendance from "./scenes/attendance";
import EventRegistration from "./scenes/registration";
import SmsService from "./scenes/sms";
import SurveyEntries from "./scenes/survey";
import TngoReward from "./scenes/tng";
import VoucherList from "./scenes/voucher";
//import Geography from "./scenes/geography";

function App() {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/*"
            element={
              <ProtectedRoute>
                <div className="app">
                  <Sidebar />
                  <main className="content">
                    <Topbar />
                    <Routes>
                      <Route index element={<Dashboard />} />
                      <Route path="/entries" element={<ContestEntries />} />
                      <Route path="/games" element={<GamesTokens />} />
                      <Route path="/aiwhatsapp" element={<WhatsappAI/>} /> 
                      <Route path="/attendance" element={<EventAttendance/>} /> 
                      <Route path="/registration" element={<EventRegistration/>} />
                      <Route path="/sms" element={<SmsService/>} />
                      <Route path="/survey" element={<SurveyEntries/>} />
                      <Route path="/tng" element={<TngoReward/>} />
                      <Route path="/voucher" element={<VoucherList/>} />
                      <Route path="*" element={<Navigate to="/" />} />
                      {/* <Route path="/geography" element={<Geography/>} /> */}
                    </Routes>
                  </main>
                </div>
              </ProtectedRoute>
            }
          />
        </Routes>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;



                {/* <Route path="/contacts" element={<Contacts/>} /> */}
                {/* <Route path="/invoices" element={<Invoices/>} /> */}
                {/* <Route path="/forms" element={<Forms/>} /> */}
                {/* <Route path="/bar" element={<Bar/>} /> */}
                {/* <Route path="/pie" element={<Pie/>} /> */}
                {/* <Route path="/line" element={<Line/>} /> */}
                {/* <Route path="/faq" element={<FAQ/>} /> */}
                {/* <Route path="/geography" element={<Geography/>} /> */}
                {/* <Route path="/calendar" element={<Calendar/>} /> */}